import { createStore } from "redux";

let _userinfo = JSON.parse(localStorage.getItem("_userinfo"));
let jenjang = JSON.parse(localStorage.getItem("jenjang"));
let myRole = JSON.parse(localStorage.getItem("myRole"));
let detailMhs = JSON.parse(localStorage.getItem("detailMhs"));

// console.log("role ==>", myRole);
// console.log("user_code==>", _userinfo.user_code);
// console.log("jenjang==>", jenjang);
// console.log("detailMhs==>", detailMhs);

const initialState = {
  token: null,
  isLogin: localStorage.getItem("isLogin")
    ? localStorage.getItem("isLogin")
    : false,
  sidebarShow: "responsive",
  isLoading: false,
  username: _userinfo ? _userinfo.username : "",
  avatar: null,
  tahun: 0,
  nama: "",
  email: "",
  id_user: _userinfo ? _userinfo.id_user : "",
  id_level: _userinfo ? _userinfo.id_level : "",
  user_code: _userinfo ? _userinfo.user_code : "",
  homebase: _userinfo ? _userinfo.homebase : "",
  jenjang: jenjang ? jenjang : [],
  list_level: [],
  semester: 0,
  myRole: localStorage.getItem("myRole")
    ? JSON.parse(localStorage.getItem("myRole"))
    : null,
  semesterAktif:null,
  detailMhs:localStorage.getItem("detailMhs")
  ? JSON.parse(localStorage.getItem("detailMhs"))
  : null,
  program: localStorage.getItem("programSelected")
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};

const store = createStore(changeState);
export default store;
