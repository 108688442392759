import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss'
import 'react-toastify/dist/ReactToastify.css'
import 'moment/locale/id'
import moment from 'moment';
import 'react-accessible-accordion/dist/fancy-example.css';
import 'react-modern-drawer/dist/index.css'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Verify = React.lazy(() => import('./util/Verify'));

class App extends Component {
  componentDidMount(){
    moment.locale('id')
  }

  render() {
    return (
      <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route path="/verify" name="Verify" render={props => <Verify {...props}/>}/>
              <Route path="/" name="Home" render={props => <TheLayout {...props}/>}/>
            </Switch>
          </React.Suspense>
      </BrowserRouter>
    )
  }
}

export default App;
